<template>
  <LinkElement v-if="childProps" v-bind="childProps">
    <slot v-if="useSlot"></slot>
    <template v-if="!useSlot">
      {{
        isEntryOfContentType(item, 'download')
          ? item.fields.title
          : item.fields.linkText
      }}
    </template>
  </LinkElement>
</template>

<script setup lang="ts">
import { LinkElement } from '@hypercodestudio/basler-components';
import type { Props as LinkProps } from '@hypercodestudio/basler-components/dist/components/elements/LinkElement.vue';
import type { IDownload, ILink } from '~/lib/ContentfulService';
import { getLinkIconPosition } from '~/utils/getLinkIconPosition';
import { isEntryOfContentType } from '~/utils/guards/isEntryOfContentType';

interface Props {
  item: ILink | IDownload;
  useSlot?: boolean;
}

const props = defineProps<Props>();

const hasDocument =
  isEntryOfContentType(props.item, 'link') && props.item.fields.documentId;
const hasSoftware =
  isEntryOfContentType(props.item, 'link') && props.item.fields.softwareId;

const childProps: Ref<LinkProps> = computed(() =>
  !hasDocument && !hasSoftware && isEntryOfContentType(props.item, 'download')
    ? getPropsForDownload(props.item)
    : getPropsForLink(props.item)
);

function getPropsForLink(item: ILink): LinkProps {
  const link = useBuildLinkInterface(item);
  return {
    link,
    targetBlank: item.fields?.linkTarget === '_blank',
    size: item.fields?.linkStyleSize,
    styleType: item.fields?.linkStyle ?? 'default',
    iconName: item.fields?.linkIcon,
    iconType: getLinkIconPosition(item.fields?.linkIconPosition)
  };
}

function getPropsForDownload(item: IDownload): LinkProps {
  const link = useBuildLinkInterface(item);
  return {
    link,
    targetBlank: true,
    size: 'medium',
    styleType: 'default',
    iconType: 'no-icon'
  };
}
</script>
